<template>
  <div style="height: 100%">
    <GmapMap
      ref="mapRef"
      :center="mapCenter"
      :zoom="7"
      map-type-id="roadmap"
      style="width: 100%; height: 100%"
      :options="{styles: mapStyles, tilt }">
      <template v-for="apl in apls">
        <GmapMarker
          :key="apl._id + 'marker'"
          :position="apl.center"
          :clickable="true"
          @click="showApl(apl)"
          :label="{
            text: apl.icon.showLabel ? $vuetify.lang.t(`$vuetify.${apl.name}`) +', ' + $vuetify.lang.t(`$vuetify.${apl.county}`) : ' ',
            fontWeight: 'bold',
            color: theme === 'dark' ? 'white' : 'black'}"
          :icon="{
            url: getIcon(apl.icon),
            scaledSize: { width: 40 * apl.icon.scale, height: 60 * apl.icon.scale },
            origin: { x: 0, y: 0 },
            anchor: { x: (40 * apl.icon.scale) / 2, y: 60 * apl.icon.scale },
            labelOrigin: { x: (40 * apl.icon.scale) / 2, y: 60 * apl.icon.scale + 10 }
          }"
        />
        <GmapPolygon :paths="decodePath(apl)" :options="polyOptions(apl)" :key="apl._id + 'poly'" />
      </template>
    </GmapMap>
  </div>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue'
// import mapStyles from '@/mapstyles/light.json'
import SERVER_URL from '@/config'

export default {
  name: 'reabilitare-index',

  data: () => ({
    apls: [],
    mapCenter: {
      lat: 46.0043292,
      lng: 25.1010639
    },
    tilt: 0
  }),

  computed: {
    google: getGoogleMapsAPI,
    decodePath () {
      return apl => {
        if (this.google) {
          const poly = this.google.maps.geometry.encoding.decodePath(apl.borders.polyString)

          return poly
        } else {
          return []
        }
      }
    },
    polyOptions () {
      return apl => ({
        fillColor: apl.borders.fill,
        strokeColor: apl.borders.stroke,
        strokeWeight: 2
        // fillOpacity
        // strokeOpacity
      })
    },
    mapStyles () {
      return require(`../mapstyles/${this.selectedMapStyle}.json`) // the module request
    },
    selectedMapStyle () {
      return this.$store.getters.selectedMapStyle
    },
    theme () {
      return this.$store.getters.theme
    }
  },

  methods: {
    showApl (apl) {
      this.$router.push({ name: 'APLMap', params: { id: apl._id }, meta: { apl } })
    },
    getIcon (icon, type = 'icon') {
      const noFile = type === 'logo' ? '/logos/logo-tud-mic.png' : '/icons/apl-icon-orphan.png'
      return SERVER_URL + (icon && icon.url ? icon.url : noFile)
    },
  },

  async mounted () {
    try {
      const apls = await this.$store.dispatch('fetchAllAPLs', true)
      this.apls = Object.freeze(apls)

      await this.$gmapApiPromiseLazy()
      const map = await this.$refs.mapRef.$mapPromise

      const bounds = new this.google.maps.LatLngBounds()

      this.apls.forEach(apl => {
        const coords = this.google.maps.geometry.encoding.decodePath(apl.borders.polyString)

        coords.forEach(coord => {
          bounds.extend(coord)
        })
      })

      map.fitBounds(bounds)
    } catch (err) {
      console.log(err)
    }
  }
}
</script>
