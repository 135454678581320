<template>
  <v-menu>
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn text v-bind="attrs" v-on="{ ...tooltip, ...menu }" :small="$vuetify.breakpoint.xsOnly" icon>
            <!-- <v-icon left v-if="$vuetify.breakpoint.smAndUp">{{ colIcons.earth }}</v-icon> -->
            <rhb-flag :country="language.countryCode" :size="$vuetify.breakpoint.xsOnly ? 'small' : 'normal'" style="margin: 0"/>
          </v-btn>
        </template>
        <span>{{ $vuetify.lang.t('$vuetify.ui.languagePicker') }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index" @click="changeLanguage(item)">
        <v-list-item-avatar>
          <rhb-flag :country='item.countryCode' :size="$vuetify.breakpoint.xsOnly ? 'small' : 'normal'" style="margin: 0 -1em"/>
        </v-list-item-avatar>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import SERVER_URL from '@/config'
import Flag from '@/components/Flag'

import { mandatoryIcons } from '@/utilities'

export default {
  name: 'RhbI18n',

  components: {
    'rhb-flag': Flag
  },

  data: () => ({
    colIcons: Object.freeze(mandatoryIcons)
  }),

  computed: {
    language () {
      return this.$store.getters.currentLanguage
    },
    items () {
      return this.$store.getters.languages
    }
  },

  methods: {
    changeLanguage (item) {
      this.$store.commit('setCurrentLanguage', item)
    }
  },

  async mounted () {
    try {
      const response = await fetch(SERVER_URL + '/dictionary/' + this.language.languageCode + '.json?' + new Date().getTime())

      if (!response.ok) {
        throw new Error('Dictionary not fetched.')
      }

      const lang = await response.json()

      this.$vuetify.lang.locales[this.language.languageCode] = lang
      this.$vuetify.lang.current = this.language.languageCode
    } catch (err) {
      console.log(err)
    }
  }
}
</script>
